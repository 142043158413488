import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { ASSETS_SVGS_BASE_URL, BREAKPOINTS, palette } from 'uibook';

export const StyledBreadcrumbs = styled.div`
  width: 100%;
  background-color: ${palette.blue[500]};
  padding: ${px2Rem(8)} ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(24)};
  display: flex;
  align-items: center;
  overflow-x: scroll;
  position: absolute;
  top: ${px2Rem(151)};
  z-index: 98;

  @media (min-width: 333px) {
    top: ${px2Rem(134)};
  }

  @media (min-width: 560px) {
    top: ${px2Rem(117)};
  }

  @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
    top: ${px2Rem(165)};
  }

  a {
    font-size: ${px2Rem(14)};
    line-height: ${px2Rem(17)};
    cursor: pointer;
    color: ${palette.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    text-wrap: nowrap;

    &:hover {
      text-decoration: underline;
      color: ${palette.blue[200]};
    }

    &::after {
      content: '';
      background-image: url('${ASSETS_SVGS_BASE_URL}/chevron-right-white.svg');
      background-repeat: no-repeat;
      background-size: ${px2Rem(12)};
      height: ${px2Rem(12)};
      width: ${px2Rem(12)};
      display: inline-block;
      padding: 0 ${px2Rem(8)};
      background-position: center;
    }

    &.active {
      color: ${palette.pink[300]};
      pointer-events: none;

      &::after {
        display: none;
      }
    }
  }
`;
